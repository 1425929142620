interface State {
  bikes: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
      commentCount: number
    }
  >

  helmets: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
      commentCount: number
    }
  >

  intercoms: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
      commentCount: number
    }
  >
}

export const useGarageStore = defineStore('bike', {
  state: (): State => ({
    bikes: new Map(),
    helmets: new Map(),
    intercoms: new Map()
  }),

  actions: {
    initBike(id: number, likedByMe: boolean, likeCount: number, commentCount: number) {
      this.bikes.set(id, { likedByMe, likeCount, commentCount })
    },

    likeBike(id: number) {
      const bike = this.bikes.get(id)

      if (!bike) return

      this.bikes.set(id, { ...bike, likedByMe: true, likeCount: bike.likeCount + 1 })
    },

    unlikeBike(id: number) {
      const bike = this.bikes.get(id)

      if (!bike) return

      this.bikes.set(id, { ...bike, likedByMe: false, likeCount: bike.likeCount - 1 })
    },

    commentBike(id: number) {
      const bike = this.bikes.get(id)

      if (!bike) return

      this.bikes.set(id, { ...bike, commentCount: bike.commentCount + 1 })
    },

    initHelmet(id: number, likedByMe: boolean, likeCount: number, commentCount: number) {
      this.helmets.set(id, { likedByMe, likeCount, commentCount })
    },

    likeHelmet(id: number) {
      const helmet = this.helmets.get(id)

      if (!helmet) return

      this.helmets.set(id, { ...helmet, likedByMe: true, likeCount: helmet.likeCount + 1 })
    },

    unlikeHelmet(id: number) {
      const helmet = this.helmets.get(id)

      if (!helmet) return

      this.helmets.set(id, { ...helmet, likedByMe: false, likeCount: helmet.likeCount - 1 })
    },

    commentHelmet(id: number) {
      const helmet = this.helmets.get(id)

      if (!helmet) return

      this.helmets.set(id, { ...helmet, commentCount: helmet.commentCount + 1 })
    },

    initIntercom(id: number, likedByMe: boolean, likeCount: number, commentCount: number) {
      this.intercoms.set(id, { likedByMe, likeCount, commentCount })
    },

    likeIntercom(id: number) {
      const intercom = this.intercoms.get(id)

      if (!intercom) return

      this.intercoms.set(id, { ...intercom, likedByMe: true, likeCount: intercom.likeCount + 1 })
    },

    unlikeIntercom(id: number) {
      const intercom = this.intercoms.get(id)

      if (!intercom) return

      this.intercoms.set(id, { ...intercom, likedByMe: false, likeCount: intercom.likeCount - 1 })
    },

    commentIntercom(id: number) {
      const intercom = this.intercoms.get(id)

      if (!intercom) return

      this.intercoms.set(id, { ...intercom, commentCount: intercom.commentCount + 1 })
    }
  }
})
