{
  "%@ finished a trip": "{0} a fini un tour",
  "Starting-Point": "Point de départ",
  "Fastest": "Direct",
  "duration": "durée",
  "Long": "long",
  "Short": "court-métrage",
  "Medium": "à point",
  "Export-as-gpx": "Exportation vers GPX",
  "Export-and-save": "Exporter et sauvegarder",
  "destination": "destination",
  "Hours": "horaires",
  "Hold on a second. We are calculating your route": "Attendez une seconde, s'il vous plaît. Nous calculons votre itinéraire....",
  "My Account": "Mon compte",
  "Go to Settings": "Aller à Paramètres",
  "End-Date": "Date de fin",
  "Start-Date": "Date de début",
  "Invite-Users": "inviter des utilisateurs",
  "HAVE-A-PRO-X-MAS": "HAVE A PRO X-MAS!",
  "Our-way-to-say-thank-you-get-percentage-first-year": "Nossa maneira de agradecer por fazer parte da comunidade RISER.\n\nReceba {0} de desconto no seu primeiro ano como membro do RISER PRO agora.",
  "First-year": "1º ano",
  "No-commitment-cancel-at-any-time": "Sem compromisso, cancelar a qualquer momento.",
  "then": "depois {0}",
  "%@ added a new bike": "{0} a ajouté une nouvelle moto",
  "%@ and you are now friends on RISER": "{0} et vous êtes maintenant amis sur RISER",
  "%@ sent you a friend request": "{0} vous a envoyé une demande d'ami",
  "%@ liked your section": "{0} a aimé votre section",
  "%@ commented on your section": "{0} a commenté votre section",
  "%@ liked your trip": "{0} a aimé votre voyage",
  "%@ commented on your trip": "{0} a commenté sur votre tour",
  "%@ liked your bike": "{0} a aimé votre moto",
  "%@ commented on your bike": "{0} a commenté sur ta moto",
  "%1@ and %2d others like this": "{0} et {1} autres aiment ça",
  "%1d bikers drove here %2d times": "{0} motards ont conduit ici {1} fois",
  "%@ likes this": "{0} aime ça",
  "%d bhp": "{0} bhp (puissance de freinage)",
  "%d friends": "{0} amis",
  "%d hour(s) ago": "Il y a {0} heure(s)",
  "%d minute(s) ago": "Il y a {0} minute(s)",
  "%d of %d": "{0} sur {1}",
  "%d people like this": "{0} personnes aiment ça",
  "%d second(s) ago": "Il y a {0} seconde(s)",
  "Accept": "Accepter",
  "Account & Settings": "Paramètres & Compte",
  "Add Bike": "Ajouter une Moto",
  "Add comment...": "Ajouter un commentaire...",
  "Add friend": "Ajouter comme ami",
  "Add to my Roadbook": "Ajouter à mon Roadbook",
  "An unknown error occured. We are sorry for the inconvenience.": "Une erreur inconnue est survenue. Nous sommes désolés du dérangement.",
  "and": "et",
  "Anyone": "N'importe qui",
  "Are you sure you want to delete this photo?": "Etes-vous sûr de vouloir supprimer cette photo?",
  "Are you sure you want to discard this trip?": "Êtes-vous sûr de vouloir jeter ce tour?",
  "Are you sure you want to report this user?": "Êtes-vous sûr de vouloir signaler cet utilisateur?",
  "Are you sure? This action can not be undone.": "Êtes-vous sûr? Cette action ne peut pas être annulée.",
  "avg speed": "Ø vitesse",
  "Basic": "Basic",
  "Build year (optional)": "Année de construction (Optionnel)",
  "Build year": "Année de construction",
  "By signing up you accept our": "En vous inscrivant, vous acceptez notre",
  "Cancel": "Annuler",
  "Caution!": "Avertissement!",
  "Change Password": "Changer le mot de passe",
  "Check out RISER at https:\/\/riserapp.com\/": "Consultez RISER à l'adresse https:\/\/riserapp.com\/",
  "choose bike": "Choisissez une moto",
  "Choose Brand": "Choisissez un Fabricant",
  "Choose build year": "Choisissez l'année de construction",
  "Choose Model": "Choisissez un modèle",
  "City": "Ville",
  "Climb": "Escalade",
  "Climb \/ Fall": "Prise \/ Perte d'altitude",
  "Coast": "Côte",
  "Complete Signup": "Compléter l'inscription",
  "Confirm new password": "Confirmer le nouveau mot de passe",
  "Connect with Facebook": "Connecter avec Facebook",
  "Continue with Facebook": "Continuer avec Facebook",
  "Create Section": "Créer une section",
  "Create special sections and share them with the community": "Créez des sections spéciales et partagez-les avec la communauté",
  "Curves": "Courbes",
  "Current Password": "Mot de passe actuel",
  "Dark": "Sombre",
  "Decline": "Déclin",
  "Delete Account": "Supprimer le Compte",
  "Delete Bike": "Supprimer la moto",
  "Delete Section": "Supprimer la Section",
  "Delete Trip": "Supprimer le Tour",
  "Description": "Description",
  "Desert": "Désert",
  "Discover popular roads with live weather and forecast": "Découvrez de nouvelles routes avec des conditions météorologiques et des prévisions",
  "Discover": "Découvrir",
  "Display": "Afficher",
  "Distance": "Distance",
  "edit friends": "modifier les amis",
  "Edit Bike": "Modifier la Moto",
  "Edit Photo": "Modifier la Photo",
  "Edit Section": "Modifier la Section",
  "Edit Trip": "Modifier le Tour",
  "Email": "Email",
  "Engine Power (optional)": "Puissance du moteur (Optionnel)",
  "Engine Power": "Puissance du moteur",
  "Enter current password": "Entrer le mot de passe actuel",
  "Enter new password": "Entrez un nouveau mot de passe",
  "Enter your name in order to make it possible for your friends to find you...": "Entrez votre nom afin de permettre à vos amis de vous trouver ...",
  "Error": "Erreur",
  "Facebook": "Facebook",
  "Feed": "Feed",
  "Finish": "Terminer",
  "First Name": "Prénom",
  "Forgot password?": "Mot de passe oublié?",
  "Friday Ride": "Parcours de Vendredi",
  "Friends": "Amis",
  "Garage": "Garage",
  "Go!": "Aller!",
  "Heads up!": "Attention!",
  "How did it go?": "Comment c'était?",
  "How was the weather?": "Comment était la météo?",
  "Imperial": "Impérial",
  "Info": "Info",
  "Invalid email \/ password combination": "Combinaison de Email \/ mot de passe invalide",
  "Invite friends": "Invitez vos amis",
  "Invite your friends to join RISER": "Invitez vos amis à rejoindre RISER",
  "Join us at RISER": "Rejoignez-nous sur RISER",
  "Just now": "Juste maintenant",
  "kmh": "km\/h",
  "Last Name": "Nom de famille",
  "Link to trip": "Lien du Tour",
  "Loading weather...": "Chargement du temps ...",
  "Log out": "Logout",
  "Logging you in...": "Nous vous connectons",
  "Login": "Login",
  "Looking for matching sections...": "À la recherche de sections correspondantes ...",
  "Manufacturer": "Fabricant",
  "Map Type": "Type de carte",
  "Matching sections": "Sections correspondantes",
  "Max Speed": "Vitesse maximale",
  "Membership": "Adhésion",
  "Message": "Message",
  "Messenger": "Messenger",
  "Metric": "Métrique",
  "Miscellaneous": "Divers",
  "Model": "Modèle",
  "Monday Ride": "Parcours de Lundi",
  "More": "Plus",
  "Mountain": "Montagne",
  "mph": "mph",
  "My Profile": "Mon profil",
  "New Password": "Nouveau mot de passe",
  "Next": "Prochain",
  "Nickname (optional)": "Surnom (optionnel)",
  "Nickname": "Surnom",
  "No Internet Connection": "Pas de connexion Internet",
  "No matching sections found.": "Aucune section correspondante n'a été trouvée.",
  "No note added.": "Aucune note n'a été ajoutée.",
  "Notifications": "Notifications",
  "Offroad": "Hors des routes",
  "Ok": "OK",
  "Only me": "Seulement moi",
  "Oops!": "Oups!",
  "Open Settings": "Ouvrir les paramètres",
  "or": "ou",
  "Passwords have to consist of min 8 and max 32 characters.": "Les mots de passe doivent consister de au moins 8 et max 32 caractères.",
  "Password": "Mot de passe",
  "Password reset": "Réinitialiser le mot de passe",
  "Pause": "Pause",
  "Pending": "En attente",
  "Photo Library": "Galerie de photos",
  "Photo": "Photo",
  "Photos": "Photos",
  "Photos along this section": "Photos de cette section",
  "Please choose at least a brand and a model to add a bike to your collection.": "Choisissez au moins une marque et un modèle pour ajouter une moto  à votre collection.",
  "Please enter a valid email address": "S'il vous plaît, mettez une adresse email valide",
  "Please enter an email and a password": "Entrez un email et un mot de passe",
  "Please provide the email address you registered with at RISER: ": "Veuillez fournir l'adresse e-mail que vous avez enregistrée chez RISER:",
  "Privacy": "Confidentialité",
  "Privacy Policy": "Politique de confidentialité",
  "Pro": "PRO",
  "Rank": "Rang",
  "Remove from roadbook": "Supprimer du roadbook",
  "Report User": "Dénoncer un utilisateur",
  "Requested": "Demandé",
  "Resume": "Reprendre",
  "RISER Feedback": "RISER Feedback",
  "RISER TV": "RISER TV",
  "Roadbook": "Roadbook",
  "Saturday Ride": "Parcours de Samedi",
  "Save": "Sauvegarder",
  "Sea level (max\/min)": "Niveau de la mer (max \/ min)",
  "Secret Link": "Lien Secret",
  "Section Title": "Titre de la section",
  "Successfully added to your roadbook": "Ajouté avec succès à votre roadbook",
  "Section": "Section",
  "Sections": "Sections",
  "Send Feedback": "Envoyer du Feedback a RISER",
  "Settings": "Paramètres",
  "Share Link": "Partager le lien",
  "Show Photo": "Afficher la photo",
  "Signing you up...": "Nous vous enregistrons...",
  "Sorry, we couldn't establish a connection to the server.": "Désolé, nous n'avons pas pu établir de connexion avec le serveur.",
  "Sponsored Post": "Message sponsorisé",
  "Start Trip": "Commencer le Tour",
  "Step 1\/2": "Étape 1\/2",
  "Step 2\/2": "Étape 2\/2",
  "Streets": "Rues",
  "Success": "Succès",
  "Summary": "Résumé",
  "Sunday Ride": "Parcours de Dimanche",
  "Tell us what you think about RISER. What do you like? What can we do better? What features are you missing?": "Dites-nous ce que tu pense de RISER. Qu'est-ce que tu aimes? Que peut-on ameliorer? Quelles sont les caractéristiques manquantes?",
  "Terms of Service": "Conditions d'utilisation",
  "Terrain": "Terrain",
  "The email address you entered already exists": "L'adresse email que vous avez entrée existe déjà",
  "The password you entered doesn't match your current password.": "Le mot de passe que vous avez entré ne correspond pas à votre mot de passe actuel.",
  "The passwords you entered don't match.": "Les mots de passe que vous avez entrés ne correspondent pas.",
  "Thursday Ride": "Parcours de Jeudi",
  "Time": "Temps",
  "Top speed": "Vitesse de pointe",
  "Track your trips and organize them in your personal roadbook": "Suivez vos tour et organisez-les dans votre roadbook personnel",
  "Trips": "Tours",
  "Tuesday Ride": "Parcours de Mardi",
  "Turn on to generate share link": "Activez pour générer le lien de partage",
  "Unexpected Error": "Erreur Inattendue",
  "Unfriend": "supprimer ami",
  "Units": "Unités",
  "Update": "Mise à jour",
  "Update failed": "Mise à jour a échoué",
  "Updating bike...": "Mise à jour de la moto...",
  "Updating password...": "Mise à jour du mot de passe...",
  "Updating section...": "Mise à jour de la section...",
  "Updating trip...": "Mise à jour du tour...",
  "Updating user credentials...": "Mise à jour des informations d'identification...",
  "Uploading bike...": "Téléchargement de la moto vers le serveur...",
  "Uploading section...": "Téléchargement de la section vers le serveur...",
  "Uploading trip...": "Téléchargement du tour vers le serveur...",
  "Version": "Version",
  "Scenery": "Scénique",
  "Warning": "Attention",
  "Water": "Eau",
  "Wednesday Ride": "Voyage de Mercredi",
  "Welcome back!": "Bienvenue de retour!",
  "Welcome to the family!": "Bienvenue à la famille!",
  "Who can see your trip?": "Qui peut voir votre tour?",
  "Who was with you on the road?": "Qui était avec vous sur la route?",
  "Yes": "Oui",
  "You and %d others like this": "Tu et {0} autres aiment ça",
  "You have to grant RISER access to Facebook in order to login.": "Vous devez accorder à RISER l'accès à Facebook pour vous connecter.",
  "You like this": "Tu aimes ça",
  "Your password was changed successfully.": "Votre mot de passe à été changé avec succès.",
  "hp": "hp",
  "Camera": "Caméra",
  "Gallery": "Galerie",
  "Nothing-to-show-in-your-feed-yet": "Rien à afficher dans votre feed encore ...",
  "Connect-with-your-friends-on-RISER-in-order-to-see": "Connectez-vous avec vos amis sur RISER afin de voir ce qu'ils font",
  "Open-one-of-your-trips-and-create-interesting-sect": "Ouvrez un de vos parcours et créez des sections intéressantes pour que d'autres puissent explorer.",
  "Send": "Envoyer",
  "Are-you-sure-you-want-to-delete-your-bike": "Êtes-vous sûr de vouloir supprimer votre moto?",
  "Are-you-sure-you-want-to-delete-this-section": "Êtes-vous sûr de vouloir supprimer cette section?",
  "Do-you-want-to-log-out": "Voulez-vous vous déconnecter?",
  "Select": "Sélectionner",
  "No-title": "Pas de titre",
  "%@-ride": "{0} parcours",
  "Monday": "Lundi",
  "Tuesday": "Mardi",
  "Wednesday": "Mercredi",
  "Thursday": "Jeudi",
  "Friday": "Vendredi",
  "Saturday": "Samedi",
  "Sunday": "Dimanche",
  "Share-using": "Partager utilisant...",
  "Would-you-like-to-unfriend": "Vous voulez supprimer la personne en tant qu'ami?",
  "Accept-Friendship": "Voulez-vous supprimer cette personne de votre liste d'amis?",
  "Offline": "Hors ligne",
  "Sign-Up": "S'inscrire",
  "You-can-add-photos-to-your-profile-and-your-trips": "Vous pouvez ajouter des photos à votre profil et à vos tours.",
  "No-bikes-to-show": "Pas de motos à montrer",
  "Add-your-bikes-and-they-will-appear-here-in-the-li": "Ajoutez vos moto et ils apparaîtront ici dans la liste",
  "No-friends-to-show": "Aucun ami à montrer",
  "No-sections-to-show": "Aucune section à afficher",
  "Create-sections-from-within-your-trips-or-add-sect": "Créez des sections à partir de vos tours ou ajoutez des sections d'autres utilisateurs à votre roadbook",
  "No-trips-to-show": "Aucun tour à afficher",
  "No-photos-to-show": "Aucune photo à afficher",
  "When-you-go-for-a-ride-an-take-photos-along-the-wa": "Lorsque vous allez faire un tour, prenez des photos en cours de route, elles apparaîtront ici",
  "People-you-might-now": "Personnes que vous connaissez peut-être",
  "%d-trips": "{0} tours",
  "Please-enter-a-Section-Title": "Entrez un titre pour la section",
  "This-special-part-of-your-trip": "Cette partie spéciale de votre voyage ...",
  "Use-the-slider-to-define-the-part-of-your-trip-tha": "Utilisez le curseur pour définir la partie de votre voyage qui vous a fait sentir \"WOW!\"",
  "Enter-a-descriptive-name-for-your-section": "Entrez un nom descriptif pour votre section",
  "Section-Tags": "Tags de la section",
  "Assign-up-to-5-tags-to-your-section-which-helps-to": "Ajoutez jusqu'à 5 Tags à votre section qui vous aide à les catégoriser",
  "Select-the-photos-you-want-to-share-alongside-this": "Sélectionnez les photos que vous souhaitez partager ensemble avec cette section",
  "If-you-liked-a-part-of-your-trip-in-particular-you": "Si vous avez aimé une partie de votre voyage en particulier, vous pouvez en créer une section. Vos sections seront partagées avec la communauté et aideront les autres motards à découvrir de nouvelles routes. Toutes les sections sont publiques.",
  "Delete-friend-request": "Supprimer la demande d'ami?",
  "loading": "chargement",
  "Section-Tags-min-1-max-5": "Tags de la section (min 1, max 5)",
  "Please-assign-at-least-one-tag-to-your-new-section": "Veuillez attribuer au moins une étiquette à votre nouvelle section",
  "Go-for-a-ride-and-your-trips-will-appear-here": "Faites un tour et vos voyages apparaîtront ici",
  "A-part-of-your-trip-made-you-feel-%@": "Une partie de votre voyage vous a fait sentir \"{0}\"?",
  "WOW": "SENSATIONNEL",
  "Got-it": "J'ai compris",
  "Describe-your-section-a-little-bit": "Décrivez votre section un peu...",
  "Subscribe-to-newsletter": "Abonnez vous au bulletin d'information",
  "Delete": "Effacer",
  "Register-now": "S'inscrire maintenant!",
  "Want-to-connect": "Vous voulez vous connecter?",
  "Signing-up-is-free-easy-and-very-fast-Two-simple-s": "L'inscription est gratuite, facile et très rapide. Deux étapes simples et vous pouvez profiter de l'expérience RISER complète.",
  "Nice-bike": "Belle moto!",
  "Time-to-create-your-profile": "Temps de créer votre profil!",
  "Add-your-profile-and-cover-photo-to-give-your-acco": "Ajoutez votre profil et photo de couverture pour donner à votre compte un style individuelle. Inscrivez-vous afin que d'autres personnes puissent vous trouver!",
  "Awesome": "Impressionnant!",
  "Time-to-register": "Heure de s'inscrire ...",
  "Share-your-trip-with-friends": "Partagez votre voyage avec vos amis!",
  "Sign-up-and-create-special-sections-organize-them": "Inscrivez-vous et créez des sections spéciales, organisez-les dans votre roadbook et partagez-les avec d'autres!",
  "Nice-track": "Belle piste!",
  "Sign-up-in-order-to-load-sections-into-your-roadbo": "Inscrivez-vous pour charger des sections dans votre roadbook et organisez-les à partir de là!",
  "You-finished-a-trip": "Vous avez terminé un voyage",
  "Start-testing": "Commencez à tester!",
  "Hide-top-speed": "Masquer la vitesse supérieure",
  "Hide-start-and-end": "Masquer le début et la fin",
  "Driving": "En conduite",
  "Auto-Pause": "Pause automatique",
  "RISER-uses-acceleration-data-to-provide-you-with-d": "RISER utilise les données d'accélération pour vous fournir des statistiques détaillées sur vos déplacements.",
  "Create-getaway": "Créer un Getaway",
  "Journey": "Périple",
  "Cruising": "Cruising",
  "Dynamic": "Dynamique",
  "create": "créer",
  "Visibility": "Visibilité",
  "Details": "Détails",
  "Conversation": "Conversation",
  "Title": "Titre",
  "Start-Time": "Heure de début",
  "End-Time": "Heure de fin",
  "Meeting-Point": "Point de rencontre",
  "Getaway-Duration": "Durée du Getaway",
  "Driving-Style": "Style de conduite",
  "Short-Spin": "Spin court",
  "Day-Trip": "Excursion d'une journée",
  "Sporty": "Sportif",
  "Give-others-an-idea-of-how-long-your-getaway-shoul": "Donnez aux autres une idée de combien de temps votre Getaway devrait durer environ.",
  "Select-a-riding-style-so-others-know-what-to-expec": "Choisissez un style de conduite pour que les autres sachent à quoi s'attendre de ce Getaway.",
  "Comfy-riding-speed-with-several-breaks": "Vitesse de conduite confortable avec plusieurs pauses",
  "More-swifty-driving-style-than-cruising-with-the-o": "Un style de conduite plus rapide que le mode Cruising avec une pause occasionnelle",
  "Sporty-driving-style-and-not-too-many-breaks": "Style de conduite sportive et pas trop de pauses",
  "Duration": "Duration",
  "see-all-%d-bikers": "voir tous les {0} motards",
  "Invited-%d": "Invité ({0})",
  "Pick-a-date-optional": "Choisissez une date (facultatif)",
  "Enter-a-title-optional": "Entrez un titre (facultatif)",
  "Enter-a-detailed-description-optional": "Entrez une description détaillée (facultatif)",
  "a-few-hours": "1-2 heures",
  "several-hours": "plusieurs heures",
  "several-days": "plusieurs jours",
  "Search": "Chercher...",
  "Add": "Ajouter",
  "Close": "Fermer",
  "Search-address": "Rechercher adresse...",
  "Getaway": "Getaway",
  "AnyoneGetawayExplanation": "Votre Getaway sera visible publiquement pour tout le monde dans la zone de découverte et peut-être le feed d'autres personnes. Tout le monde peut joindre.",
  "OnlyMeGetawayExplanation": "Votre Getaway ne sera visible que par vous et les personnes que vous avez invitées. Il n'apparaîtra pas à vos amis dans la zone de découverte ou ailleurs.",
  "FriendsGetawayExplanation": "Votre Getaway n'apparaîtra que dans la zone de découverte de vos amis et dans le feed de vos amis. Seuls vos amis peuvent rejoindre.",
  "AnyoneExplanation": "Tout le monde peut voir cet élément. Il sera visible pour les personnes qui visitent votre profil et pourrait apparaître dans les résultats de recherche.",
  "FriendsExplanation": "Seuls les amis sur RISER peuvent voir cet élément. Il ne sera visible que par vos amis dans votre profil et ne s'affichera pas dans les résultats de recherche pour d'autres personnes.",
  "OnlyMeExplanation": "Vous seul pouvez voir cet élément. Il n'apparaîtra pas sur votre profil et ne s'affichera dans aucun résultat de recherche.",
  "Private": "Privé",
  "My-getaways": "Mes Getaways",
  "Find-getaways": "Trouver des Getaway",
  "Getaways": "Getaways",
  "My-Roadbook": "Mon Roadbook",
  "Creating-getaway": "Création du Getaway...",
  "Invitations-%d": "Invitations ({0})",
  "Upcoming-%d": "À venir ({0})",
  "Past-%d": "Passé ({0})",
  "TBA": "A être annoncé",
  "Want-to-go-for-a-ride-with-others": "Vous voulez faire un tour avec les autres?",
  "Discussion": "Discussion",
  "Share": "Partager",
  "Attending": "Participe",
  "Edit": "Modifier",
  "Participants": "Intervenants",
  "Remove": "Supprimer",
  "Remove-user": "Supprimer l'utilisateur",
  "Do-you-want-to-remove-%@-from-the-getaway": "Voulez-vous supprimer {0} du Getaway?",
  "Processing": "En traitement...",
  "Featured-%d": "En vedette ({0})",
  "Browse": "Feuilleter",
  "Getaways-nearby-%d": "Getaways à proximité ({0})",
  "Me": "Moi",
  "Participants-%d": "Participants ({0})",
  "Featured": "En vedette",
  "Are-you-sure-you-want-to-delete-this-getaway": "Êtes-vous sûr de vouloir supprimer ce Getaway?",
  "Delete-Getaway": "Supprimer le Getaway",
  "Edit-Getaway": "Modifier le Getaway",
  "Organizer": "Organisateur",
  "Participating": "Participe",
  "You-have-an-upcoming-getaway-with-%@": "Vous avez un Getaway à venir avec {0}",
  "%@-created-a-getaway": "{0} a créé un Getaway",
  "No-messages-to-show-Be-the-first-to-start-this-con": "Aucun message à afficher. Soyez le premier à démarrer cette conversation.",
  "Leave-Getaway": "Laisser le Getaway",
  "Leave": "Laisser",
  "You-created-a-getaway": "Vous avez créé un Getaway",
  "Want-to-invite-people-to-a-trip": "Vous voulez inviter des gens à un trip?",
  "Create-a-getaway-set-a-date-and-meeting-point-and": "Créez un Getaway, définissez une date et un point de rencontre et invitez vos amis. Organiser un tour de groupe n'a jamais été aussi facile!",
  "Participate": "Participer",
  "%@-invited-you-to-a-getaway": "{0} vous a invité à un Getaway",
  "You-are-the-organizer": "Vous êtes l'organisateur",
  "%1@-changed-the-details-of-getaway-%2@": "{0} a changé les détails du Getaway {1}",
  "%1@-commented-on-getaway-%2@": "{0} a commenté le Getaway {1}",
  "%1@-changed-the-meeting-point-and-start-time-of-ge": "{0} a changé le point de rencontre et l'heure de début du Getaway {1}",
  "%1@-will-attend-your-getaway-%2@": "{0} participera à votre Getaway {1}",
  "%1@-changed-the-meeting-point-of-getaway-%2@": "{0} a changé le point de rencontre du Getaway {1}",
  "%1@-changed-the-start-time-of-getaway-%2@": "{0} a changé l'heure de début du Getaway {1}",
  "You-are-not-participating-in-any-getaways-yet-Crea": "Vous ne participez pas encore à des Getaway. Créez votre propre Getaway et invitez vos amis à une balade en moto!",
  "There-are-no-getaways-around-your-current-location": "Il n'y a pas de Getaway autour de votre emplacement actuel. Créer un Getaway publique et il se présentera pour les motards à proximité de vous.",
  "No-getaways-to-display": "Pas de Getaway à afficher",
  "Silent-here-so-far": "Silencieux ici, jusqu'à présent",
  "You-have-to-accept-our-Terms-of-Service-in-order-t": "Vous devez accepter nos Conditions d'utilisation afin de continuer à utiliser l'application.",
  "SMS-or-Email": "SMS ou Email",
  "Groups": "Groupes",
  "My-Groups": "Mes Groupes",
  "Getaways-enable-you-to-organize-joint-trips-with-y": "Getaways vous permettent d'organiser des voyages communs avec vos amis. Précisez le style de conduite d'un Getaway pour donner aux autres une idée de ce à quoi ils peuvent s'attendre, fixez l'heure de départ et le point de rencontre et prenez la route!",
  "Plan-joint-trips-with-your-friends": "Planifiez des Getaways conjointes avec vos amis",
  "Worldwide": "Monde entier",
  "Closed": "Fermé",
  "Secret": "Secret",
  "Public": "Public",
  "Request-pending": "Demande en attente",
  "Respond-to-invitation": "Répondre à l'invitation",
  "Group-Privacy": "Confidentialité du groupe",
  "Tap-to-set-home-base": "Cliquez pour définir la base principale",
  "Request-access": "Demander l'accès",
  "You-are-a-member": "Vous êtes membre",
  "Group-home-base": "Base principale du groupe",
  "Enter-a-short-description-for-your-group": "Décrivez brièvement votre groupe",
  "Group-name": "Nom du groupe",
  "Create-group": "Créer un groupe",
  "Group-members-%d": "Membres du groupe ({0})",
  "You-are-the-owner": "Vous êtes le propriétaire",
  "%d-members": "{0} membres",
  "Join-group": "Rejoindre le groupe",
  "Admin": "Admin",
  "Enter-group-name": "Entrez le nom du groupe",
  "%1@-accepted-the-invitation-to-the-group-%2@": "{0} a accepté votre invitation à rejoindre le groupe {1}",
  "%1@-wants-to-join-the-group-%2@": "{0} souhaite rejoindre le groupe {1}",
  "%1@-invited-you-to-join-the-group-%2@": "{0} vous a invité à rejoindre le groupe {1}",
  "%1@-modified-the-group-%2@": "{0} a modifié le groupe {1}",
  "Do-you-want-to-remove-%@-from-the-group": "Voulez-vous supprimer {0} du groupe ?",
  "PublicGroupExplanation": "Tous les utilisateurs RISER inscrits peuvent trouver et rejoindre un groupe public. Aucune demande n'a besoin d'être envoyée à l'admin pour rejoindre un groupe public.",
  "ClosedGroupExplanation": "Tous les utilisateurs RISER peuvent trouver ce groupe. Une demande d'accès doit être envoyée et acceptée par l'administrateur d'un groupe fermé pour le rejoindre.",
  "SecretGroupExplanation": "Seuls les membres et les utilisateurs invités peuvent trouver ou consulter le groupe secret et ses informations. Les utilisateurs ne peuvent rejoindre le groupe que sur invitation de l'administrateur. Ce groupe n'est pas visible par les membres qui n'appartenant pas au groupe.",
  "Member-%d": "Membre ({0})",
  "Owner-%d": "Propriétaire ({0})",
  "Edit-group": "Modifier le groupe",
  "Delete-group": "Supprimer le groupe",
  "No-groups-to-display": "Aucun groupe à afficher",
  "Are-you-sure-you-want-to-delete-this-group?": "Êtes-vous sûr(e) de vouloir supprimer ce groupe ?",
  "Are-you-sure-you-want-to-leave-this-group?": "Êtes-vous sûr(e) de vouloir quitter ce groupe ?",
  "Manage-requests": "Gérer les demandes",
  "Hey-%@!": "Bonjour {0} !",
  "Do-you-have-a-bike-in-your-garage?-You-can-add-it-here-to-get-detailed-statistics-on-how-much-distance-and-time-you-spend-on-it.-Other-bikers-will-be-able-to-check-it-out-when-they-visit-your-profile.": "Possédez-vous une moto dans votre garage ? Vous pouvez l'ajouter ici pour obtenir des statistiques détaillées sur la distance que vous parcourez et le temps que vous y passez. D'autres motocyclistes pourront les consulter en visitant votre profil.",
  "Thank-you!": "Merci !",
  "Oh-no!": "Oh non !",
  "Please-tell-us-what-we-can-do-better-or-what-you-are-still-missing.-Your-feedback-helps-us-to-improve-the-app-constantly.": "Merci de nous informer des améliorations potentielles que nous pouvons apporter. Vos retours nous aident à améliorer constamment l'application.",
  "Pending-Invitations": "Invitations en attente",
  "Membership-Requests": "Demandes d'adhésion",
  "Admin-area": "Zone admin",
  "Invite": "Inviter",
  "Accept-all": "Accepter tout",
  "Manage-members": "Gérer les membres",
  "You-want-to-be-part-of-a-group?": "Voulez-vous faire partie d'un groupe ?",
  "Offline-maps": "Cartes hors ligne",
  "Please-enter-a-group-name": "Veuillez entrer un nom de groupe",
  "Creating-group": "Création de groupe ...",
  "This-group-name-is-already-taken,-please-choose-another-one": "Ce nom de groupe est déjà pris, veuillez en choisir un autre",
  "Requests": "Demandes",
  "Members": "Membres",
  "Groups-nearby-(%d)": "Groupes à proximité de ({0})",
  "%1@-joined-the-group-%2@": "{0} a rejoint le groupe {1}",
  "You-joined-the-group-%@": "Vous avez rejoint le groupe {0}",
  "Request-pending-%d": "Demande en attente ({0})",
  "Cancel-request": "Annuler la demande",
  "No": "Non",
  "Share-trip": "Partager le voyage",
  "Brand": "Marque",
  "Enter-missing-brand-name": "Entrez le nom de marque manquant",
  "Enter-missing-model-name": "Entrez le nom du modèle manquant",
  "Can't-find-your-brand?": "Vous ne trouvez pas votre marque?",
  "Can't-find-your-model?": "Vous ne trouvez pas votre modèle?",
  "Current-Location": "Localisation actuelle",
  "Route-%d": "Route {0}",
  "Enable-live-tracking-and-send-a-link": "Activez le LiveTracking et envoyez un lien à votre famille et vos amis afin qu'ils puissent vous suivre sur la carte.",
  "reWind": "reWind",
  "Adventure-Routing": "Adventure Routing",
  "Find-the-curviest-roads-with-the-most-beautiful-scenery": "Trouvez les routes les plus sinueuses avec les plus beaux paysages et transformez votre voyage en aventure.",
  "We-get-you-from-A-to-B": "Nous vous amenons de A à B. Pas aussi vite, mais aussi excitant que possible.",
  "Tracking": "Suivi d'emplacement",
  "Navigating": "Navigue",
  "Adventure-Navigator": "Adventure Navigator",
  "Curvy": "Courbée",
  "Supercurvy": "Super-Courbée",
  "Max-Duration": "Durée Max.",
  "Dont-have-time?": "Pas de temps?",
  "Live-Tracking": "LiveTracking",
  "Fastest-way": "Parcours plus rapide",
  "Apply": "Appliquer",
  "Share-rewind-link": "Partager le lien reWind",
  "Download": "Télécharger",
  "Show-rewind": "Montrer reWind",
  "Caution-when-activating-fastest-way": "Mise en garde! L'activation de la navigation à la vitesse la plus rapide désactivera le mode Adventure Routing et vous risquez de vous retrouver sur les routes et les autoroutes les plus ennuyeuses.",
  "Where-do-you-want-to-go": "Où veux-tu aller {0}?",
  "Group-names-must-be-between": "Les noms de groupes doivent être compris entre 2 et 500 lettres",
  "Invalid-file-type-only-jpg-png-allowed": "Type de fichier invalide. Seulement JPG et PNG autorisés",
  "Select-area": "Sélectionnez une zone",
  "Roundtrip": "Aller-retour",
  "We-are-sorry-we-couldnt-find-a-route-with-the-specified-settings": "Nous sommes désolés, nous n'avons pas trouvé d'itinéraire avec les paramètres spécifiés.",
  "Route-options": "Options de route",
  "Avoid-motorways": "Eviter les autoroutes",
  "Try-14-days": "Essayez 14 jours",
  "then-%@-per-year": "puis {0} \/ année",
  "Yearly": "Annuellement",
  "%@-per-month": "{0} \/ mois",
  "Monthly": "Mensuellement",
  "%@-per-year": "{0} \/ an",
  "save-money": "Économiser",
  "Get-riser": "Obtenez RISER",
  "go-back-in-time": "Remonter dans le temps? Sur votre aventure avec RISER vous pouvez! Regardez votre reWind à nouveau sur une carte 3D interactive et partagez avec vos amis.",
  "No-connection-no-problem": "Pas de connexion - pas de problème! Sur votre aventure avec RISER, les cartes hors ligne vous garderont sur la bonne voie, même dans les zones les plus reculées.",
  "curves-are-all-you-need": "Les courbes sont tout ce dont vous avez besoin? Découvrez votre itinéraire parfait avec RISER Adventure Routing et utilisez des modes exclusifs pour le créer!",
  "Adventure-navigator-pro": "Adventure Navigator PRO",
  "stay-connected-with-live-tracking": "Restez connecté avec vos amis et votre famille et tenez-les au courant de votre aventure! Envoyez-leur votre emplacement à travers la fonction LiveTracking!",
  "sometimes-its-not-about-where-you-go": "Parfois, ce n'est pas à propos de l'endroit où vous allez. Mais comment vous y arrivez. Améliorez votre aventure personnelle avec les fonctionnalités intelligentes RISER PRO.",
  "Subscription-terms": "Conditions d'abonnement",
  "Something-went-wrong-when-verifying-your-purchase": "Une erreur s'est produite lors de la vérification de votre achat. S'il vous plaît contacter notre service client.",
  "Before-you-become-a-pro": "Avant de devenir un PRO ...",
  "Contact": "Contact",
  "Feedback": "Suggestions",
  "Agree-and-sign-up": "Accepter et signer",
  "Would-you-like-to-receive-updates-via-email": "Souhaitez-vous recevoir des mises à jour de RISER par e-mail?",
  "Reason": "Raison",
  "Ambassador": "Ambassador",
  "%1@-also-commented-on-trip-%2@": "{0} a également commenté sur trip {1}",
  "%1@-also-commented-on-bike-%2@": "{0} a également commenté sur bike {1}",
  "%1@-also-commented-on-section-%2@": "{0} a également commenté sur la section {1}",
  "Show-all-comments": "Commentaires",
  "Suggestions": "Suggestions",
  "Never-lose-your-way-when-theres-no-connection-even-in-the-most-remote-areas": "Ne te perds jamais lorsqu'il n'y a pas de connexion, même dans les zones les plus reculées.",
  "The-exciting-thing-about-motorcycling-is-not": "RISER Adventure Award",
  "Conditions-of-participation": "*Conditions de participation : Chaque utilisateur de RISER dispose d'autant de Likes qu'il souhaite en attribuer. L'utilisateur est libre d'aimer tous les voyages nominés ou un seul. L'attribution des 5 x 1 an RISER PRO comme prix parmi les utilisateurs participant au vote a lieu par tirage au sort. Le(s) gagnant(s) sera(ont) informé(s) par e-mail à la fin du prix et après la publication du gagnant du prix. Les doubles gains sont exclus, aucun échange d'argent n'est possible. Pour toute question, merci de contacter support{'@'}riserapp.com.",
  "What-bike-were-you-riding": "Sur quel vélo roulais-tu ?",
  "Be-part-of-the-RISER-community": "Fais partie de la communauté RISER",
  "Enter-name": "Saisis le nom",
  "Could-not-load-weather": "Impossible de charger la météo",
  "Billed-annually": "Facturation annuelle",
  "Starting at (price)": "À partir de",
  "Free": "Gratuit",
  "Forever": "Pour toujours",
  "Free_description": "Obtiens l'application et la plateforme RISER avec ses caractéristiques de base.",
  "Open-the-app-to-purchase-RISER-PRO": "Ouvre l'application pour acheter RISER PRO. Si tu n`as pas l`application, tu peux la télécharger gratuitement via ton app store :",
  "registering-is-free": "L'inscription est 100% gratuite",
  "Pick-a-date": "Choisissez une date",
  "settings-account-deletion-disclaimer": "Lorsque vous supprimez votre compte, toutes les données associées à vous seront définitivement supprimées de nos serveurs. Cette action ne peut pas être annulée. Vous pouvez cependant créer un nouveau compte à tout moment.",
  "Sometimes it’s not about where you go, but how you get there.": "Parfois, ce n'est pas l'endroit où tu vas qui compte, mais la façon dont tu y arrives.",
  "Remember-me": "Souviens-toi de moi",
  "Thank you for your trust!": "Merci de votre confiance !",
  "You will now continue receiving our newsletter": "Tu continueras maintenant à recevoir notre bulletin d'information, qui contient des informations à jour, des nouvelles, et plus encore de RISER.",
  "You can always use your right to withdraw this permission": "Tu peux toujours utiliser ton droit de retirer cette autorisation, sans donner de raison.",
  "Signup-&-Subscription": "S'inscrire et s'abonner",
  "Get-Pro": "\nObtenir PRO",
  "Are-you-sure-you-want-to-delete-your-comment?": "Es-tu sûr de vouloir supprimer ton commentaire ?",
  "Comments": "Commentaires",
  "Start-(footer)": "Démarrer",
  "Because we’re all driven by adventure.": "Parce que nous sommes tous motivés par l'aventure.",
  "<h2>UNIQUE MOMENTS<\/h2> <p>with others<\/p>": "<h2>Moments uniques<\/h2> <p>avec les autres<\/p>",
  "Connect with your friends and like-minded people": "Établissez des liens avec des gens aux vues similaires, créez des escapades pour planifier des voyages avec vos amis ou joignez-vous à des groupes pour vous inspirer.",
  "CONNECT WITH FRIENDS": "CONNECTE-TOI AVEC TES AMIS",
  "CREATE OR JOIN GROUPS": "CRÉER OU REJOINDRE DES GROUPES",
  "The adventure starts long before we get on our bikes": "L'aventure commence bien avant de monter sur nos moto et ne se termine qu'après avoir éteint le moteur. C'est pourquoi nous voulons vous offrir bien plus qu'un itinéraire sinueux (bien sûr, nous vous le montrerons aussi) :",
  "Discover together": "Découvrir ensemble",
  "Track your adventures": "Suivez vos aventures",
  "Navigate to your destination": "Naviguez jusqu'à votre destination",
  "After": "après",
  "Save your memories": "Sauvegardez vos souvenirs",
  "Share unique moments": "partager des moments uniques",
  "Inspire other riders": "inspirer d'autres motocyclistes",
  "Riders about RISER": "Les motocyclistes à propos de RISER",
  "RISER is designed logically and easy to use": "RISER est conçu de manière logique et facile à utiliser. Il offre tout ce dont j'ai besoin pour faire de la moto. Continuez votre bon travail !",
  "The app is clean and super-easy to use": "L'application est propre et super facile à utiliser. L'enregistrement des trajets se déroule sans problème et je peux facilement documenter mes trajets avec des photos, etc. J'ai recommandé l'application à tous mes amis.",
  "This app is awesome": "Cette application est géniale. J'ai récemment trouvé de belles routes dans ma région que je ne connaissais même pas.",
  "Find your personal adventure": "Trouvez votre aventure personnelle",
  "The best routes are waiting out there and RISER will find them for you.": "Les meilleurs itinéraires vous attendent et RISER les trouvera pour vous.",
  "Discover even curvier routes with the Adventure Navigator PRO": "Découvrez des itinéraires encore plus sinueux avec l'Adventure Navigator PRO. Ne perdez jamais de vue votre destination grâce aux cartes hors ligne. Suivez vos trajets et revivez-les sur une carte 3D. Informez vos proches de votre position grâce au suivi en direct.",
  "Safety": "Sécurité",
  "Let others know where you are by sending them a secret link with your real time location": "Permettez aux autres de savoir où vous êtes en leur envoyant un lien secret avec votre emplacement en temps réel.",
  "Navigation": "Navigation",
  "Extended Routing": "Itinéraire étendu",
  "Let RISER find the curviest routes for you and use the Tour Planner to plan longer adventures": "Laissez RISER vous trouver des itinéraires super-sinueux pour que vous vous amusiez encore plus sur la route.",
  "Relive": "Revivre",
  "Watch your trip once again on an interactive 3D-map and share it with your friends": "Regardez à nouveau votre trajet sur une carte interactive en 3D et partagez-le avec vos amis",
  "Comfort": "Confort",
  "Never lose your way when there’s no connection, even in the most remote areas": "Ne perdez jamais votre chemin quand il n'y a pas de connexion, même dans les endroits les plus reculés",
  "Find out more about RISER PRO": "En savoir plus sur RISER PRO",
  "RISER ambassador adventures": "Aventures des ambassadeurs RISER",
  "Get inspired for your next trip!": "Trouvez de l'inspiration pour votre prochain voyage!",
  "Read more": "En savoir plus",
  "Highlights of our 8500 km summer tour: Italy, France, Spain, Portugal": "Points forts de notre périple estival de 8500 km : Italie, France, Espagne, Portugal",
  "As always in recent years, we have chosen a destination": "Comme toujours ces dernières années, nous avons choisi une destination (Cabo de Sao Vicente au Portugal) puis roulé dans cette direction sans planification plus précise.",
  "Be part of this adventure": "Participez à cette aventure",
  "Sign up now!": "Inscrivez-vous maintenant !",
  "pack ride": "PackRide",
  "Pack Ride Unlimited": "PackRide sans limites",
  "Upgrade-(pro)": "Upgrade",
  "Subscribed-until": "Abonnement jusqu'à",
  "Your-RISER-Team": "Votre équipe RISER",
  "Something-went-wrong-with-your-payment": "Quelque chose n'allait pas avec votre paiement...",
  "We-sent-a-verification-mail-to-%@": "Nous avons envoyé un courrier de vérification à {0}",
  "Before we can upgrade you to RISER PRO, please make sure the e-mail address you provided is correct so we can send you receipts.": "Avant que nous puissions vous mettre à niveau vers RISER PRO, s'il vous plaît assurez-vous que l'adresse e-mail que vous avez fourni est correcte afin que nous puissions vous envoyer des reçus.",
  "Verify your email address": "Vérifiez votre adresse e-mail",
  "An email has been sent to %@ before you can continue please click": "Un email a été envoyé à {0} avant de poursuivre, veuillez cliquer sur le lien que nous vous avons envoyé pour vérifier vous-même.",
  "Email sent": "Courriel envoyé",
  "Email verified": "Email verifié",
  "Your email address has been verified": "Votre adresse e-mail a été vérifiée",
  "Securely pay %@": "Payer en toute sécurité {0}",
  "Continue with payment": "Continuer avec le paiement",
  "Subtotal": "Sous-total",
  "Total": "Total",
  "Discount": "Discount",
  "Support": "Support",
  "Never miss out on the best routes and news with our Newsletter": "Ne manquez jamais sur les meilleurs itinéraires et des nouvelles à notre Newsletter",
  "Congrats!": "Félicitations!",
  "If you need support please get in touch with %@": "Nous sommes désolés pour le dérangement. Si vous avez besoin d'aide, veuillez communiquer avec {0}",
  "Code expired": "Code expiré",
  "Sorry, too late! This voucher is no longer active.": "Désolé, trop tard ! Ce bon n'est plus actif.",
  "Already used": "Déjà utilisé",
  "You already used this voucher.": "Vous avez déjà utilisé ce bon.",
  "Ride together, stay together": "Roulez ensemble, restez ensemble !",
  "Enjoy the full Pack Ride experience with RISER PRO": "Profitez de l'expérience PackRide complète avec RISER PRO : ne perdez jamais votre groupe et n'envoyez pas ou recevez des notifications.",
  "Get an even more sophisticated experience": "Obtenez même une expérience plus sophistiquée",
  "Get PRO now": "Obtenez PRO maintenant",
  "What are you waiting for?": "Qu'attendez-vous pour?",
  "come": "Viens",
  "Ride With US": "Roulez avec nous",
  "Become part of the RISER pack. We will empower you to make more memorable riding experiences worth sharing.": "Devenez membre du pack RISER. Nous vous donnerons les moyens de faire en sorte que vos expériences de conduite mémorables méritent d'être partagées",
  "Join the pack!": "Rejoignez le pack!",
  "Never loose your group and send or receive notifications.": "Ne jamais perdre votre groupe et d'envoyer ou de recevoir des notifications. (Pour le illimité expérience PackRide de plus de 30 minutes - le chef de Pack a besoin d'avoir un abonnement RISER PRO)",
  "voucher_success_message": "Vous avez maintenant accès aux fonctionnalités de RISER PRO ! \n\nVotre compte sera automatiquement réinitialisé à Basique après l'expiration de la période spécifiée.",
  "Already Pro": "Vous avez déjà RISER PRO",
  "voucher_error_already_pro_message": "Vous avez déjà un abonnement PRO. Ce bon ne peut être utilisé que par les utilisateurs de RISER Basic. \n\nCependant, vous pouvez partager ce bon avec vos amis pour leur offrir PRO.",
  "current period until": "Période en cours jusqu'au",
  "Your email address could not be verified": "Votre adresse e-mail n'a pas pu être vérifiée",
  "Find out how": "Découvre comment",
  "Show your support for female riders!": "Montre ton soutien aux femmes motardes !",
  "Show your support for female riders by doing a pack ride!": "MONTRE TON SOUTIEN AUX FEMMES MOTARDES EN FAISANT UNE RANDONNÉE EN PELOTON ! RISER t'offre gratuitement 2 mois de RISER PRO pour participer.",
  "We use technical and analytics cookies to ensure that we give you the best experience on our website": "Nous utilisons des techniques et d'analyse des cookies pour nous assurer de vous donner la meilleure expérience sur notre site.",
  "This website uses cookies": "Ce site utilise des cookies",
  "Continue to profile": "Continuer à profil",
  "Group": "Groupe",
  "Never miss out on the hottest news & tours from your motorcycle community on RISER!": "Ne manquez jamais les nouvelles et les visites les plus chaudes de votre communauté motocycliste sur RISER!",
  "Email Format": "Format Email",
  "Marketing Permissions": "Autorisations de marketing",
  "Please select all the ways you would like to hear from RISER:": "Veuillez fournir l'adresse e-mail que vous avez enregistrée chez RISER:",
  "Email - get the best stories and news": "Email - obtenez les meilleures histoires et nouvelles",
  "Customized Online Advertising - get offers tailored to your preferences": "Publicité en ligne personnalisée - obtenez des offres adaptées à vos préférences",
  "You can unsubscribe at any time by clicking the link in the footer of our emails.": "Vous pouvez vous désinscrire à tout moment en cliquant sur le lien dans le pied de page de nos e-mails.",
  "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.": "Nous utilisons Mailchimp comme plate-forme de marketing. En cliquant ci-dessous pour vous abonner, vous reconnaissez que vos informations seront transférées à Mailchimp pour traitement.",
  "Learn more about Mailchimp's privacy practices here.": "En savoir plus sur les pratiques de Mailchimp en matière de confidentialité, ici.",
  "Biannually": "Semestriel",
  "6_months_PRO_title": "6 mois PRO",
  "Currently not on your phone? Open your RISER App now and get started!": "Pas actuellement sur votre téléphone? Ouvrez RISER maintenant et commencez!",
  "Are you sure you want to leave this getaway?": "Êtes-vous sûr de vouloir supprimer ce Getaway?",
  "Invited": "Invité",
  "The App for Motorcyclists": "L'Application pour les Motards",
  "Available for free": "Disponible gratuitement",
  "Login with Email": "Connexion avec Email",
  "Register with Email": "Inscrivez-vous par e-mail",
  "What are you looking for?": "Qu'est-ce que tu cherches?",
  "We are sorry but we could not find any results for your search…": "Nous n'avons trouvé aucun résultat …",
  "Users": "Utilisateurs",
  "Enter start": "Entrez début",
  "Enter waypoint": "Entrez le point de passage",
  "Enter Destination": "Entrez la destination",
  "Sign Up or Login to join the conversation": "Inscrivez-vous ou connectez-vous pour rejoindre la conversation",
  "Created": "Créé",
  "6_months_PRO_description": "Prends tes 6 mois PRO et profite de toutes les fonctionnalités PRO pendant la moitié de l'année !",
  "One time payment": "Paiement unique",
  "1_year_PRO_description": "Bénéficie de toutes les fonctionnalités PRO et améliore ton expérience !",
  "We have updated our Terms of Service": "Nous avons mis à jour nos conditions d'utilisation. Tu peux consulter la dernière version ci-dessous.",
  "1_year_PRO_title": "1 an PRO",
  "Free_title": "Basic",
  "Already verified": "Déjà vérifié",
  "Resend verification link": "Renvoyer le lien de vérification",
  "Cancel subscription": "Annuler l'abonnement",
  "Subscription Period": "Période d'abonnement",
  "Product": "Produit",
  "Invoice Status": "Statut de la facture",
  "invoice_draft": "Projet",
  "invoice_open": "Ouvrir",
  "invoice_paid": "Payé",
  "invoice_void": "Vide",
  "invoice_uncollectible": "Incorruptible",
  "No open payments and already PRO": "Il n'y a pas de paiements en cours et tu as déjà tous les avantages de RISER PRO ! Merci pour ton soutien continu.",
  "Country": "Pays",
  "Payment details": "Détails du paiement",
  "Card holder": "Porte-cartes",
  "Street address": "Adresse",
  "State (address)": "État",
  "ZIP Code": "Code postal",
  "Card number": "Numéro de carte",
  "Secure customer authentication required before payment can be processed.": "Authentification sécurisée du client requise avant que le paiement puisse être traité.",
  "Card invalid.": "La carte n'est pas valide.",
  "Period": "Période",
  "Amount due": "Montant dû",
  "Remember that you have to manually cancel any mobile subscriptions.": "N'oublie pas que tu dois annuler manuellement tout abonnement mobile dans l'app Store correspondant.",
  "To delete your account you first need to cancel your active subscription.": "Pour supprimer ton compte, tu dois d'abord annuler ton abonnement.",
  "Invalid password reset token.": "Jeton de réinitialisation de mot de passe invalide.",
  "Send password reset email": "Demande l'email de réinitialisation du mot de passe",
  "No user with this email address was found.": "Aucun utilisateur ayant cette adresse e-mail n'a été trouvé.",
  "Type in your e-mail and we will send you a link to reset your password.": "Entrez votre e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
  "An email has been sent to %@ please follow the instructions inside to reset your password.": "Un courriel a été envoyé à {0}. Suis les instructions à l'intérieur pour réinitialiser ton mot de passe.",
  "Upgrade now and become a PRO!": "Mets-toi à jour maintenant et deviens un PRO !",
  "Discover PRO plans": "Découvre les plans PRO",
  "and more to come...": "et plus encore à venir...",
  "Subscribe now!": "Inscris-toi maintenant !",
  "Austria": "Autriche",
  "Belgium": "Belgique",
  "Bulgaria": "Bulgarie",
  "Croatia": "Croatie",
  "Cyprus": "Chypre",
  "Czech Republic": "République Tchèque",
  "Denmark": "Danemark",
  "Estonia": "Estonie",
  "Finland": "Finlande",
  "France": "France",
  "Germany": "Allemagne",
  "Greece": "Grèce",
  "Hungary": "Hongrie",
  "Ireland": "Irlande",
  "Italy": "Italie",
  "Latvia": "Lettonie",
  "Lithuania": "Lituanie",
  "Luxembourg": "Luxembourg",
  "Malta": "Malte",
  "Netherlands": "Pays-Bas",
  "Poland": "Pologne",
  "Portugal": "Portugal",
  "Azores": "Açores",
  "Madeira": "Madère",
  "Mainland (Continental Portugal)": "Portugal",
  "Romania": "Roumanie",
  "Slovakia": "Slovaquie",
  "Slovenia": "Slovénie",
  "Spain": "Espagne",
  "Sweden": "Suède",
  "United Kingdom": "Royaume-Uni",
  "You are almost there!": "Tu y es presque !",
  "Street and number": "Rue et numéro",
  "(optional) Apartment, building, floor": "(facultatif) Appartement, bâtiment, étage",
  "Tax region": "Région",
  "Pay invoice": "Payer la facture",
  "If you have any questions, please let us know: %@": "Si vous avez des questions, veuillez nous en informer : {0}",
  "now -%@%": "maintenant -{0}%",
  "14 day free trial": "Essai gratuit de 14 jours",
  "only for the annual plan": "uniquement pour le plan annuel",
  "%@-per-6-months": "{0} \/ 6 mois",
  "Copied to Clipboard": "Copié dans le clipboard",
  "Copy": "Copie",
  "or share on": "ou partage sur",
  "Challenges": "Les défis",
  "Your Rank": "Votre Rang",
  "Your Distance": "Votre distance",
  "Accept the Challenge": "Accepte le défi",
  "Leave Challenge": "Quitter le Challenge",
  "Leaderboard": "Classement",
  "No-challenges-to-show": "Il n'y a actuellement aucun défi",
  "We couldn't find any of your Facebook": "Nous n'avons trouvé aucun de vos amis Facebook car vous n'avez pas connecté Facebook à RISER. Cliquez sur le bouton pour connecter Facebook et RISER.",
  "Your Duration": "Ta durée",
  "Upcoming": "Prochain",
  "Past": "Complété",
  "Current": "Courant",
  "This-is-a-mystery-user": "Ce motard n'a pas ajouté de description. Mais pourquoi?",
  "Follow": "Suivre",
  "About": "À propos de moi",
  "Planned": "Prévu",
  "Ride this now": "Roule maintenant",
  "General": "Général",
  "Short Description": "Description",
  "Social Media": "Réseaux Sociaux",
  "Likes": "Likes",
  "Add Posting": "Ajouter un affichage",
  "Post": "Publier",
  "Add Photo": "Ajouter une photo",
  "ktm-challenge-text": "A vos agendas, LA SEMAINE MONDIALE DE L'AVENTURE se déroule du 5 au 11 juillet 2021. L'objectif principal est d'enfourcher votre vélo et de parcourir 1 000 km ou plus en sept jours. \n\n Propulsé par KTM et RISER, LA SEMAINE MONDIALE DE L'AVENTURE est l'excuse parfaite pour augmenter le kilométrage de votre moto, peu importe la marque ou le modèle que vous conduisez. \n\n Les coureurs les plus aventureux seront récompensés par des prix quotidiens et hebdomadaires et auront la chance d'être présentés sur theworldadventureweek.com et les réseaux sociaux mondiaux de KTM. \n\n Il y aura différents défis à relever chacun des sept jours, tous les coureurs parcourant 1000 km ou plus au total recevront un prix personnalisé. \n\n Plus d'informations à venir. Restez à l'écoute!",
  "ktm-challenge-title": "7 jours, 1000 kilomètres, 5 - 11 juillet 2021",
  "ktm-challenge-terms": "J'accepte les <a target=\"_blank\" href=\"https:\/\/riserapp.com\/en\/waw-terms\"> RISER <\/a> et <a target=\"_blank\" href=\"https:\/\/www.ktm.com\/en-int\/world-adventure-week\/terms---conditions.html\"> KTM <\/a> de la World Adventure Week",
  "State\/Province": "État\/Province",
  "Your Score": "Tes Points",
  "Total Points": "Total des Points",
  "Challenge": "Challenge",
  "Your Challenge Bike": "Ton moto de défi",
  "You don’t have any planned routes so far": "Tu n'as pas encore d'itinéraires planifiés jusqu'à présent. Utilise l'appli ou ouvre routeplanner.pro sur ton ordinateur pour planifier ta prochaine aventure!",
  "Are you sure you want to delete this planned route": "Voulez-vous vraiment supprimer cet itinéraire ?",
  "No planned Routes": "Pas d'itinéraires planifiés",
  "save_your_route_to_your_roadbook": "Sauvegarde ton itinéraire dans ton roadbook",
  "Name your planned route": "Quel doit être le nom de l'itinéraire prévu ?",
  "save_planned_route_successful": "Itinéraire sauvegardé avec succès dans ton roadbook.",
  "join for free": "S'inscrire gratuitement",
  "Minimum 8 characters": "Minimum 8 caractères",
  "Continue with Google": "Continuer avec Google",
  "Log in to RISER": "Connecte-toi à RISER",
  "Start": "Démarrer",
  "Skip": "Sauter",
  "Add your first bike to your garage": "Ajoute ton premier moto à ton garage",
  "Add your bike to get detailed statistics about your rides and more.": "Ajoute ton moto pour obtenir des statistiques détaillées sur tes trajets et plus encore.",
  "Set your season goal": "Fixe ton objectif pour la saison",
  "How far do you want to ride this season?": "Jusqu'où veux-tu aller cette saison ?",
  "What are you most interested in?": "Qu'est-ce qui t'intéresse le plus ?",
  "Connect with friends": "Connecte-toi avec tes amis",
  "Find_exciting_routes": "Trouve des itinéraires passionnants",
  "Share_my_rides_with_others": "Partager mes balades en moto avec d'autres personnes",
  "Organize common rides": "Organise des randonnées communes",
  "Tell us something about you": "Parle-nous un peu de toi",
  "Homebase": "Homebase",
  "Continue": "Continue",
  "Stay_up_to_date_and_get_the_latest_news": "Reste à jour et reçois les dernières nouvelles",
  "Choose Allow receive notifications and emails": "Choisis \"Autoriser\" pour recevoir des notifications et des courriels sur tes randonnées et les histoires de tes amis et de la communauté.",
  "Allow": "Permettre",
  "Later": "Plus tard",
  "number_month": "%d mois|%d mois",
  "Welcome to \u2028RISER,!": "Bienvenue à RISER, {0}!",
  "You are now all set for your first ride": "Tu es maintenant fin prêt pour ta première balade, pour explorer de nouvelles routes et pour te mesurer aux autres lors de défis. Amuse-toi bien !",
  "Today": "Aujourd'hui",
  "Unlock and test all PRO features like": "Débloque et teste toutes les fonctionnalités PRO comme LiveTracking, PackRide, les itinéraires supercurvy et plus encore {0} jours gratuitement.",
  "You will receive a reminder about when your trial ends.": "Tu recevras un rappel concernant la fin de ton essai.",
  "You will be charged for the subscription. Cancel anytime before.": "Tu seras facturé pour l'abonnement. Annule à tout moment avant.",
  "price year month after trial": "{0} \/ an ({1} \/ mois) après l'essai",
  "Start Free Trial": "Démarrer l'essai gratuit",
  "You won’t be charged until": "Tu ne seras pas facturé avant le {0} . Annule à tout moment jusqu'à 24 heures avant la fin de ton essai",
  "Unfollow": "Ne pas suivre",
  "Snap": "Direction",
  "Fullscreen": "Plein écran",
  "Trip": "Voyage",
  "Rewind Speed": "Vitesse reWind",
  "Max Altitude": "Altitude maximale",
  "Open Roadbook": "Open Roadbook",
  "Clear all Waypoints": "Effacer tous les points de cheminement",
  "Add as start point": "Ajouter comme point de départ",
  "Add as intermediate point": "Ajouter comme point intermédiaire",
  "Add as end point": "Ajouter comme point final",
  "Saving": "Économiser",
  "Direction": "Direction",
  "North": "Nord",
  "NorthEast": "Nord-Ouest",
  "East": "Est",
  "SouthEast": "Sud-Est",
  "South": "Sud",
  "SouthWest": "Sud-Ouest",
  "West": "Ouest",
  "NorthWest": "Nord-Ouest",
  "Direction_Duration": "Direction et durée",
  "Random": "Au hasard",
  "Done": "Fait",
  "bio": "Bio",
  "show": "Montrer",
  "choose_map_type": "Choisis le type de carte",
  "Statistics": "Statistiques",
  "Save Route to Your Roadbook": "Sauvegarde l'itinéraire dans ton carnet de route",
  "Season goal": "Objectif de la saison",
  "Current period": "Période actuelle :",
  "Edit profile": "Modifier le profil",
  "Download the RISER App to discover this route": "Télécharge l'application RISER pour découvrir cet itinéraire",
  "Check out the brand new": "🔥 Regarde la toute nouvelle mise à jour et élève tes voyages au niveau supérieur.",
  "Connect with friends & riders across the globe": "Connecte-toi avec des amis et des cyclistes du monde entier",
  "Plan your next \u2028adventure": "Planifie ta prochaine aventure",
  "Record your rides & share them with the community": "Enregistre tes trajets et partage-les avec la communauté.",
  "continue_with_apple": "Continuer avec Apple",
  "by_adding_your_name_and_homebase_you_make_it_easier_for_your_friends_to_find_you": "En ajoutant ton nom et ton lieu de résidence, tu permets à tes amis de te retrouver plus facilement.",
  "not_now": "Pas maintenant",
  "Create posting": "Créer un affichage",
  "Post now": "Poster maintenant",
  "What do you want to share?": "Que veux-tu partager ?",
  "Distance-this-season": "Distance cette saison",
  "Report posting": "Signaler l'affichage",
  "We need your help here": "Nous avons besoin de ton aide ici",
  "In order to evaluate your report": "Afin d'évaluer ton rapport, nous avons besoin de quelques informations de ta part. Choisis l'une des catégories et donne-nous une brève description afin que nous puissions traiter ta demande rapidement.",
  "Inappropriate Language": "Langage inapproprié",
  "Spam or Scam": "Spam ou escroquerie",
  "Sexual harassment": "Harcèlement sexuel",
  "Other": "Autre",
  "Almost there": "Nous y sommes presque...",
  "Please provide us with a short": "Merci de nous fournir une courte description avec tes propres mots. De cette façon, il nous est plus facile de comprendre et d'évaluer le contenu respectif.",
  "Why do you want to report this comment posting": "Pourquoi veux-tu signaler ce commentaire ou cette publication ?",
  "Thank you for reporting": "Merci pour ton rapport",
  "Our team will look into the matter": "Notre équipe se penchera sur la question dans les plus brefs délais",
  "Elevation": "Élévation",
  "Thank you for your feedback": "Merci pour ton commentaire. Nous l'examinerons dès que possible.",
  "Report this": "Rapporter ceci",
  "Delete this postings": "Effacer ce message",
  "Delete this comment": "Supprimer ce commentaire",
  "Press control to change angle": "Appuie sur <strong>Contrôle + Glisser<\/strong> avec la souris pour changer d'angle",
  "You": "Tu",
  "Show more": "Afficher plus",
  "load more comments": "charger plus de commentaires",
  "new bike": "nouvelle moto",
  "x_comments": "{0} 1 commentaire|{0} commentaires",
  "x_likes": "{0} Like|{0} Like",
  "posting": "Publication",
  "Unlock all PRO features": "Débloque toutes les fonctionnalités PRO et élève ta prochaine balade au niveau supérieur.",
  "your special offer": "votre offre spéciale {0}",
  "percent OFF forever": "{0} ÉTEINT",
  "Get PRO for __ now": "Obtenir PRO à {0} maintenant",
  "only": "seulement",
  "4 Days left": "Il reste {0} jours",
  "PRO membership": "Adhésion à PRO",
  "Whats_included": "Ce qui est inclus :\nPackRide illimité, itinéraires super sinueux, cartes hors ligne, reWind, LiveTracking, exportation GPX et plus encore....",
  "Achievements": "Réalisations",
  "completed": "complétés",
  "__completed": "{0} complétés",
  "Share this": "Partager",
  "profile": "Profil",
  "Please use the app to redeem this voucher": "Utilise l'appli pour échanger ce bon d'achat.",
  "Title is not unique": "Le titre n'est pas unique",
  "error": "Erreur",
  "Scan the code to download the app": "Scannez le code pour télécharger l'application.",
  "Become PRO to get the best out of RISER": "Deviens PRO pour profiter au max de RISER.",
  "action_load": "Fichier",
  "Route": "Route",
  "Plan now": "Planifie maintenant",
  "Order by": "Trier par",
  "Creation Date": "Date de création",
  "Start your own group ride or join an existing one": "Commence ta propre randonnée en groupe ou rejoins-en une pour voir où sont tes amis.",
  "Stop": "Arrêter",
  "Choose options": "Choisis les options",
  "Join": "Rejoindre",
  "packrides_nearby": "Packrides à proximité",
  "Double click to add new destination": "<strong>Double-clic<\/strong> pour ajouter une nouvelle destination.",
  "A simple double click adds a new destination to your route": "Un simple double-clic ajoute une nouvelle destination à votre itinéraire.",
  "Right Click to get additional options": "<strong>Clic droit<\/strong> pour obtenir des options supplémentaires.",
  "With a right click you get the option to add a new start point, destination or waypoint in between": "En faisant un clic droit, t'as l'option d'ajouter un nouveau point de départ, une destination ou un point de passage entre les deux.",
  "Drag & drop waypoints to change the order": "<strong>Faites glisser et déposez<\/strong> des points de passage pour changer l'ordre.",
  "Simply drag a waypoint and drop it to easily arrange the order of waypoints to get exactly the route you desire": "Il suffit de faire glisser un point de passage et de le déposer pour organiser facilement l'ordre des points de passage afin d'obtenir exactement l'itinéraire que vous désirez.",
  "Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination": "Sans point intermédiaire, c'est trop loin. Veuillez planifier cet itinéraire avec des points de passage jusqu'à cette destination.",
  "Congratulations, %@": "Félicitations,\n{0} 🎉",
  "You unlocked a new achievement": "Vous avez débloqué une nouvelle réalisation.|Vous avez débloqué {0} nouvelles réalisations.",
  "achievement unlocked": "Réalisation \ndébloquée 🎉",
  "Get your code": "Obtiens ton code",
  "on your next cardo device": "sur ton\nprochain\nCardo\nappareil",
  "PRO only": "PRO seulement",
  "your_special_cardo_offer": "Votre offre spéciale",
  "cardo_offer_code_text": "Utilisez le code ci-dessous pour obtenir votre réduction spéciale de {0}% sur votre prochain appareil Cardo (Packtalk Pro exclu).",
  "visit_cardo_shop": "Visitez la boutique Cardo",
  "Route Planner": "Planificateur d'itinéraire",
  "start_action": "Démarrer",
  "update_soft_title": "Mise à jour disponible",
  "update_hard_title": "Vous devez mettre à jour cette application",
  "update_message": "Nous avons apporté de nombreuses améliorations à notre application. Veuillez mettre à jour vers la dernière version.",
  "update_now": "Mettez à jour maintenant",
  "Direct competition": "Concurrence directe",
  "Enjoying Riser": "Vous profitez de RISER, {0} ?",
  "Your rating helps us grow": "Votre évaluation nous aide à grandir et à améliorer notre application. Manifestez votre soutien d'un simple clic !",
  "Thanks for the high-five": "Merci pour le high-five, {0}! 🌟",
  "Would you mind sharing your rating": "Pourriez-vous partager votre note sur le {0}? Cela nous aide vraiment et ne prend qu'un instant!",
  "Maybe next time": "La prochaine fois peut-être",
  "Submit": "Soumettre",
  "Rate us in the": "Évaluez-nous dans le {0}",
  "%s Followers": "{0} Abonnés",
  "%s Following": "{0} Suit",
  "Followers": "Abonnés",
  "Following": "Suivre",
  "Blocked Users": "Utilisateurs bloqués",
  "Follow back": "Suivre en retour",
  "Block": "Bloquer",
  "Unblock": "Débloquer",
  "Answer": "Répondre",
  "XY would like to follow you": "{0} veut te suivre.",
  "Respond to request": "Répondre à la demande.",
  "Delete follower request": "Supprimer la demande de suivi ?",
  "Are you sure you want to unfollow": "Êtes-vous sûr(e) de vouloir ne plus suivre {0} ?",
  "Are you sure you want to block": "Êtes-vous sûr(e) de vouloir bloquer {0} ?",
  "Your follow request inbox is empty.": "Votre boîte de réception des demandes de suivi est vide. Commencez à vous connecter avec les autres en leur envoyant des demandes !",
  "You are not following another rider": "Vous ne suivez encore aucun motard. Plongez dans la communauté, découvrez d'autres passionnés et suivez leurs voyages pour alimenter votre propre aventure !",
  "It's quiet on the road right now with no followers in your rearview": "Il est calme sur la route en ce moment, sans aucun suiveur dans votre rétroviseur. Commencez à partager vos aventures à moto et interagissez avec la communauté pour faire rouler votre voyage !",
  "Remove follower": "Supprimer l'abonné",
  "Are you sure you want to remove XY": "Êtes-vous sûr(e) de vouloir retirer {0} de vos abonnés ?",
  "We couldn't find any riders with this name": "Nous n'avons pas pu trouver d'utilisateur avec ce nom. Peut-être n'a-t-il pas encore rejoint RISER ? Utilisez la fonction d'invitation pour amener vos collègues passionnés dans notre communauté.",
  "XY sent you a follow request": "{0} vous a envoyé une demande de suivi.",
  "XY accepted your follow request": "{0} a accepté votre demande de suivi.",
  "You got X new follower request": "{0} et une autre personne vous a envoyé une demande de suivi.|{0} et {0} autres personnes vous ont envoyé une demande de suivi.",
  "XY started following you": "{0} vient de vous suivre !",
  "XY and others started following you": "{0} et quelqu'un d'autre vient de vous suivre ! 🎉|{0} et {0} autres viennent de vous suivre ! 🎉",
  "Auto-Accept Followers": "Acceptation automatique des followers",
  "Accept new followers automatically": "Acceptez automatiquement de nouveaux followers. Simplifiez votre parcours et développez votre communauté sans effort !",
  "empty_text_blocked_users": "Vous n'avez encore bloqué aucun utilisateur. Votre parcours est sans obstacle ! \n\nContinuez à rouler et à vous connecter dans l'esprit d'une grande communauté.",
  "Answer follow request": "Répondre à la demande de suivi",
  "XY sent a request": "{0} a envoyé une demande",
  "Introducing our new Follower Logic": "Présentation de notre nouveau système de suiveurs",
  "Discover and connect effortlessly with our new follower system": "Découvrez et connectez-vous sans effort avec notre nouveau système de suiveurs. Suivez votre passion et inspirez les autres à chaque trajet.",
  "Connections preserved": "Connexions préservées",
  "Your existing friendships are automatically carried over": "Vos amitiés existantes sont automatiquement transférées. Aucune action nécessaire — juste des connexions continues et sans interruption.",
  "Enable Auto-Accept": "Activer l'Acceptation Automatique",
  "Open your journeys to more eyes": "Ouvrez vos parcours à plus de regards! Optez pour l'acceptation automatique et laissez vos tours, voyages et balades inspirer un public sans cesse croissant.",
  "Auto-accept requests": "Accepter automatiquement les demandes",
  "Manual review requests": "Examiner les demandes",
  "Save setting": "Sauvegarder les paramètres",
  "Mark all read": "Marquer tout comme lu",
  "Users on RISER can automatically follow you": "Les utilisateurs sur RISER peuvent vous suivre et interagir avec vous automatiquement.",
  "Only users that you confirm can follow your rides": "Seuls les utilisateurs que vous confirmez peuvent suivre vos trajets.",
  "Copy link": "Copier le lien",
  "Search results for query": "Résultats de recherche pour \"{0}\"",
  "Invite Followers": "Inviter des abonnés",
  "Report": "Signaler",
  "Confirm": "Confirmer",
  "Reject": "Rejeter",
  "block the user": "Bloquer l'utilisateur",
  "Overwrite": "Écraser",
  "Set Goal": "Définir un objectif",
  "Tell us about yourself": "Parle-nous de toi",
  "Instagram": "Instagram",
  "Url": "Url",
  "Youtube": "Youtube",
  "Website": "Site web",
  "Your Climb": "Ton ascension",
  "Register": "S'inscrire",
  "Enter your details": "Entre tes détails",
  "Name": "Nom",
  "Redeem this code using the app": "Échangez ce code en utilisant l'application",
  "Invalid Voucher": "Bon invalide",
  "Try again": "Essayez à nouveau",
  "Voucher": "Bon",
  "user_state_blocked": "Bloqué",
  "Show this trip": "Afficher ce voyage",
  "___Participants": "{0} Participant|{0} Participants",
  "Show all": "Tout afficher",
  "Joined": "Rejoint",
  "Suggested": "Suggéré",
  "trending-photo": "Photo tendance",
  "Bike": "Moto",
  "Share and reWind": "Partager et reWind",
  "No comments for this trip": "Pas de commentaires pour ce trip. Ta chance d'être le premier...",
  "No bike assigned": "Aucune moto attribuée",
  "special_offer": "Offre spéciale",
  "Share your profile": "Partage ton profil",
  "pack-ride": "PackRide",
  "export-tours": "Exporter des circuits",
  "upgrade-pro-yearly-discount-text": "Le plus populaire",
  "Welcome to RISER Pro": "🎉 Bienvenue sur RISER Pro {0}!",
  "You have now access to all RISER features": "Maintenant, tu as accès à toutes les fonctionnalités de RISER sans aucune limitation, et ce n'est pas tout, avec ton achat, tu soutiens notre travail et le développement de futures fonctionnalités.\n\nMerci!",
  "Latest added": "Dernier ajout",
  "Select Order": "Sélectionner la commande",
  "Satellite": "Satellite",
  "cardo_offer_valid_until": "Ce code est valide jusqu'au {0}.",
  "finish-and-continue": "Clique sur le bouton ci-dessous pour terminer le processus et reprendre là où tu t’es arrêté.",
  "finish-upgrade": "Terminer la mise à niveau",
  "Avoid tolls": "Éviter les péages",
  "You can edit the default name of the GPX file": "Vous pouvez modifier le nom du fichier GPX dans le champ de texte avant l'exportation.",
  "Export": "Exporter",
  "redeem-voucher-heading": "Échangez votre bon",
  "redeem-voucher-download-step": "Commencez par télécharger notre application gratuite et créer votre compte.",
  "redeem-voucher-action": "Échanger le bon",
  "redeem-voucher-redeem-step": "Ensuite, cliquez sur le lien ci-dessous pour échanger votre bon dans l'application.",
  "enjoy-riser-pro": "Profite de RISER Pro!",
  "voucher-invalid-get-pro-button": "Obtenez PRO quand même",
  "Become a RISER PRO": "Devenez\nun RISER\nPRO",
  "And be part of the exclusive PRO rider community.": "Et faites partie de\nla communauté \nexclusive des\npilotes PRO.",
  "Request": "Demande",
  "no-trips-yet-text": "On dirait que tu n’as pas encore de voyages.",
  "show-older-comments": "Afficher les commentaires plus anciens",
  "profile-image": "Image de profil",
  "cover-image": "Image de couverture",
  "invited-to-join-group": "Tu as été invité à rejoindre {0}.",
  "quick-invite": "Invitation rapide",
  "this-month": "Ce mois-ci",
  "this-year": "Cette année",
  "alltime": "Tout le temps",
  "group-leaderboard-empty-text": "On dirait que le classement est vide pour cette période. C’est ta chance !",
  "x-members": "{0} membre|{0} membres",
  "group-join-requested": "{0} a demandé à rejoindre",
  "group-description-empty-placeholder": "Ce groupe n'a pas encore ajouté de description.",
  "total-distance": "Distance totale",
  "total-duration": "Durée totale",
  "total-climb": "Ascension totale",
  "create-posting-group-input-placeholder": "Que voulez-vous partager avec votre groupe ?",
  "posts": "Posts",
  "create-post": "Créer un post",
  "group-posting-empty-text": "Il semble qu'il n'y ait encore aucun post dans ce groupe. Soyez le premier à poster quelque chose !",
  "join-my-group-on-riser": "Rejoins mon groupe sur RISER",
  "share-and-invite": "Partager & Inviter",
  "scan-qr-to-invite": "Scannez le QR pour inviter",
  "check-riser-installed": "Assurez-vous que l'application RISER est installée.",
  "share-link": "Partager le lien",
  "you-have-no-groups-empty-text": "Il semble que vous ne soyez pas dans un groupe. Découvrez de nouveaux groupes ou créez le vôtre !",
  "create-your-own-group": "Créez votre propre groupe",
  "join-group-to-see-more": "Rejoignez le groupe pour en voir plus",
  "stats": "Stats",
  "no-results-found": "Aucun résultat trouvé",
  "something-went-wrong": "Quelque chose a mal tourné",
  "Week": "Semaine",
  "Month": "Mois",
  "Year": "Année",
  "All time": "Toutes",
  "file-to-large-message": "Fichier trop volumineux. La taille maximale du fichier est de {0} Mo.",
  "Altitude": "Altitude",
  "highest": "le plus haut",
  "lowest": "le plus bas",
  "above sea level": "{0} au-dessus du niveau de la mer",
  "Week vs week before": "Distance hebdomadaire",
  "Bikes": "Motos",
  "Countries": "Pays",
  "Longest Ride": "Voyage le plus long",
  "Riders in": "Motards à {0}",
  "Month vs month before": "Distance mensuelle",
  "Trip distances": "Distances des voyages",
  "Trips per week day": "Voyages par jour",
  "Calendar view": "Voyages par jour",
  "Year vs year before": "Distance annuelle",
  "Distance per year": "Distance par an",
  "Pack Rides": "PackRides",
  "New": "Nouveau",
  "received": "reçu",
  "given": "donné",
  "Show all statistics": "Toutes les statistiques",
  "Tools": "Outils",
  "Previous": "Précédent",
  "auto-accept-follow-requests": "Acceptation automatique des demandes de suivi",
  "manage-subscription-and-billing": "Gérer l’abonnement et la facturation",
  "new": "nouveau",
  "Trips per month": "Voyages par mois",
  "Average:": "Moyenne:",
  "Start week on": "Commencer la semaine le",
  "Fall": "Perte d'altitude",
  "Yearly Statistics": "Statistiques annuelles",
  "Monthly Statistics": "Statistiques mensuelles",
  "Strongest Month": "Mois le plus fort",
  "Strongest Day": "Jour le plus fort",
  "Edit profile photo": "Modifier la photo de profil",
  "Edit cover photo": "Modifier la photo de couverture",
  "People you follow": "Personnes que vous suivez",
  "Riders in your country": "Motards dans votre pays",
  "Set homebase for leaderboard": "Définir la homebase pour le classement",
  "Postings": "Publications",
  "current-week": "Semaine en cours",
  "week-before": "Semaine d’avant",
  "average": "Moyenne",
  "All": "Tout",
  "promo-code-only-for-yearly-warning": "Le code promo n’a pas été appliqué car il n’est valable que pour l’abonnement annuel de RISER PRO.",
  "end_navigation": "Terminer la navigation",
  "Export as GPX Track": "Exporter en tant que GPX track",
  "Helmets": "Casques",
  "Intercoms": "Intercoms",
  "Intercom": "Intercom",
  "Helmet": "Casque",
  "Add to garage": "Ajouter au garage",
  "Service": "Service",
  "Next service in": "Prochain service dans…",
  "Tap to add photo": "Appuyez pour ajouter une photo",
  "Helmet type": "Type de casque",
  "Helmet details": "Détails du casque",
  "Year of purchase": "Année d’achat",
  "Add helmet": "Ajouter un casque",
  "Add intercom": "Ajouter un intercom",
  "Intercom details": "Détails de l’intercom",
  "Show service history": "Afficher l’historique du service",
  "Service history": "Historique du service",
  "Show garage": "Afficher le garage",
  "Service details": "Détails du service",
  "Full-face": "Casque intégral",
  "Flip-up": "Casque modulable",
  "Open-face": "Casque ouvert",
  "Half-helmet": "Casque demi-jet",
  "Off-road-helmet": "Tout-terrain",
  "Adventure": "Aventure",
  "Are you sure you want to delete your helmet": "Êtes-vous sûr de vouloir supprimer votre casque ?",
  "Are you sure you want to delete your intercom": "Êtes-vous sûr de vouloir supprimer votre intercom ?",
  "sold": "Vendu",
  "Mark as sold": "Marquer comme vendu",
  "Revoke sold status": "Révoquer le statut de vendu",
  "No intercom added to your garage": "Aucun intercom ajouté à votre garage…",
  "No helmet added to your garage": "Aucun casque ajouté à votre garage…",
  "Now": "Maintenant",
  "Add some notes like \"Oil change\"": "Ajoutez quelques notes comme « vidange d’huile »…",
  "Add next service": "Ajouter le prochain service",
  "Add service interval": "Ajouter un intervalle de service",
  "Remark": "Remarque",
  "No helmet added": "Aucun casque ajouté",
  "No bike added": "Aucune moto ajoutée",
  "No intercom added": "Aucun intercom ajouté",
  "Bike details": "Détails de la moto",
  "Enter manufacturer information": "Saisir les informations du fabricant",
  "Enter code": "Entrer le code",
  "Enter code here": "Entrez le code ici",
  "Unlock Your Benefits": "Débloquez vos avantages",
  "Enter your voucher code below to access exclusive features or discounts!": "Entrez votre code promo ci-dessous pour accéder à des fonctionnalités exclusives ou des réductions !",
  "Redeem": "Échanger",
  "new intercom": "Nouvel intercom",
  "new helmet": "Nouveau casque",
  "Log service": "Enregistrer le service",
  "%@ liked your helmet": "{0} a aimé votre casque",
  "commented on your helmet": "{0} a commenté votre casque",
  "commented on your intercom": "{0} a commenté votre intercom",
  "liked your intercom": "{0} a aimé votre intercom"
}