import { type RISERAPI } from '~/data/api'
import { BikeUpsertData, HelmetUpsertData, IntercomUpsertData } from '~/types/bikes'

export class GarageService {
  API?: RISERAPI

  constructor(API: RISERAPI) {
    this.API = API
  }

  async getBikeBrands() {
    const response = await this.API?.getBikeBrands()
    return response?.data
  }

  async createHelmet(userId: number, data: HelmetUpsertData) {
    const response = await this.API?.createHelmet(userId, data)

    return response?.data
  }

  async updateHelmet(helmetId: number, data: HelmetUpsertData) {
    const response = await this.API?.updateHelmet(helmetId, data)
    return response?.data
  }

  async createIntercom(userId: number, data: IntercomUpsertData) {
    const response = await this.API?.createIntercom(userId, data)
    return response?.data
  }

  async updateIntercom(intercomId: number, data: IntercomUpsertData) {
    const response = await this.API?.updateIntercom(intercomId, data)
    return response?.data
  }

  async getBike(bikeID: string) {
    const response = await this.API?.getBike(bikeID)
    return response?.data
  }

  async createBike(userId: number, data: BikeUpsertData) {
    const response = await this.API?.createBike(userId, data)
    return response?.data
  }

  async updateBike(bikeId: number, data: BikeUpsertData) {
    const response = await this.API?.updateBike(bikeId, data)
    return response?.data
  }

  async getHelmetBrands() {
    const response = await this.API?.getHelmetBrands()
    return response?.data
  }

  async getIntercomBrands() {
    const response = await this.API?.getIntercomBrands()
    return response?.data
  }

  async updateHelmetPhoto(helmetId: number, photo: File) {
    const response = await this.API?.updateHelmetPhoto(helmetId, photo)
    return response?.data
  }

  async updateIntercomPhoto(intercomId: number, photo: File) {
    const response = await this.API?.updateIntercomPhoto(intercomId, photo)
    return response?.data
  }

  async updateBikePhoto(bikeId: number, photo: File) {
    const response = await this.API?.updateBikePhoto(bikeId, photo)
    return response?.data
  }

  async addAdditionalBikePhoto(bikeId: number, photo: File) {
    const response = await this.API?.addAdditionalBikePhoto(bikeId, photo)
    return response?.data
  }

  async deleteAdditionalBikePhoto(photoId: number) {
    const response = await this.API?.deletePhoto(photoId)
    return response?.data
  }

  async deleteBike(bikeId: number) {
    const response = await this.API?.deleteBike(bikeId)
    return response?.data
  }

  async deleteHelmet(helmetId: number) {
    const response = await this.API?.deleteHelmet(helmetId)
    return response?.data
  }

  async deleteIntercom(intercomId: number) {
    const response = await this.API?.deleteIntercom(intercomId)
    return response?.data
  }

  async getHelmet(helmetId: string) {
    const response = await this.API?.getHelmet(helmetId)
    return response?.data
  }

  async getIntercom(intercomId: string) {
    const response = await this.API?.getIntercom(intercomId)
    return response?.data
  }
}
