import { LocalStorageService, SessionStorageService } from '../services/localStorage'
import { PostingService } from '../services/postingService'
import { CommentsService } from '../services/commentService'
import { LikeService } from '../services/likeService'
import { RoutingService } from '../services/routingService'
import { UserService } from '../services/userService'
import { TripService } from '../services/tripService'
import { SectionService } from '../services/sectionService'
import { AuthenticationService } from '../services/authenticationService'
import { AchievementService } from '../services/achievementsService'
import { GarageService } from '../services/garageService'
import { GroupService } from '../services/groupService'
import { GetawayService } from '../services/getawayService'
import { SearchService } from '../services/searchService'
import { ChallengesService } from '../services/challengesService'
import { NativeClientService } from '../services/nativeClientService'
import { AmplitudeTrackingService } from '../services/amplitudeTrackingService'
import { AMPLITUDE_EVENTS } from '~/types/amplitudeEvents'

import { GeoService } from '../services/geoService'

import { API } from '../data/api'
import { SubscriptionService } from '~/services/subscriptionService'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()

  const apiKey = config.public.amplitudeAPIKey
  const facebookAppId = config.public.facebookAppId

  const { cookiesEnabledIds } = useCookieControl()

  const tripStore = useTripStore()
  const postingStore = usePostingStore()
  const garageStore = useGarageStore()

  // HANDLE Amplitued tracking
  AmplitudeTrackingService.setApiKey(apiKey)

  if (cookiesEnabledIds.value) {
    AmplitudeTrackingService.init()
  }

  watch(
    () => cookiesEnabledIds.value,
    (current) => {
      if (current?.includes('amplitude')) {
        // TODO Should not happen if we are in dev mode
        AmplitudeTrackingService.init()
      } else {
        AmplitudeTrackingService.optOut()
      }
    },
    { deep: true }
  )

  return {
    provide: {
      AMPLITUDE_EVENTS,
      PostingService: new PostingService(API),
      CommentsService: new CommentsService(API),
      LikeService: new LikeService(API, tripStore, postingStore, garageStore),
      RoutingService: new RoutingService(API),
      UserService: new UserService(API, SessionStorageService, LocalStorageService),
      AchievementService: new AchievementService(API, SessionStorageService),
      TripService: new TripService(API),
      SectionService: new SectionService(API),
      GroupService: new GroupService(API),
      AuthenticationService: new AuthenticationService(API, facebookAppId),
      ChallengesService: new ChallengesService(API),
      GeoService: new GeoService(API, SessionStorageService),
      GetawayService: new GetawayService(API),
      GarageService: new GarageService(API),
      SearchService: new SearchService(API),
      SubscriptionService: new SubscriptionService(API),
      NativeClientService: new NativeClientService(),
      localStorage: LocalStorageService,
      sessionStorage: SessionStorageService,
      AmplitudeTrackingService
    }
  }
})
