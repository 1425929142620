<template>
  <NavigationTopBarItemBase @close="close" data-testid="top-bar-search-item">
    <template #button>
      <FontAwesomeIcon icon="magnifying-glass" class="tw-text-sm" />
    </template>

    <template #default="{ triggerClose }">
      <div class="content-container">
        <div class="tw-p-4">
          <autocomplete
            ref="autocomplete"
            v-model="item"
            class="search-input round"
            :items="[]"
            :component-item="renderItem"
            :input-attrs="{
              id: 'searchItem',
              autofocus: true,
              placeholder: $t('What are you looking for?'),
              autocomplete: 'off'
            }"
            :auto-select-one-item="false"
            @update-items="search"
            @change="onChange"
          />
        </div>

        <div v-if="query.length > 0" class="tw-mt-4 tw-overflow-y-auto tw-px-4">
          <div class="search-results">
            <FontAwesomeIcon
              v-if="loading"
              icon="spinner"
              spin
              class="spinner-icon tw-m-4 tw-block"
              size="3x"
            />

            <div v-if="noResults" class="no-results">
              <div class="padding tw-text-center">
                <FontAwesomeIcon icon="magnifying-glass" class="tw-text-3xl tw-text-black/40" />

                <i18n-t
                  scope="global"
                  tag="p"
                  class="padding dark-gray"
                  keypath="We are sorry but we could not find any results for your search…"
                />
              </div>
            </div>

            <div v-if="users.length > 0" class="search-results-group">
              <h2 class="tw-mb-3">
                <i18n-t scope="global" class="search-results-title" keypath="Users" />
              </h2>

              <div class="tw-flex tw-flex-col tw-gap-3">
                <UserItem
                  v-for="friend in users.slice(0, 5)"
                  :key="friend.id"
                  class="search-results-item hover"
                  :item="friend"
                  mini
                  @click="triggerClose"
                />
              </div>

              <router-link
                v-if="users.length > 5"
                class="search-results-link"
                :to="`/search?q=${encodeURIComponent(query)}&type=users`"
                @click="triggerClose"
              >
                <i18n-t scope="global" keypath="Show more" />
              </router-link>
            </div>

            <div v-if="groups.length > 0" class="search-results-group tw-mt-6">
              <h2 class="tw-mb-3">
                <i18n-t scope="global" class="search-results-title" keypath="Groups" />
              </h2>

              <div class="tw-flex tw-flex-col tw-gap-3">
                <GroupsSuggestionItem
                  v-for="group in groups.slice(0, 5)"
                  :key="group.id"
                  class="search-results-item"
                  :group="group"
                  @click="triggerClose"
                />
              </div>

              <router-link
                v-if="groups.length > 5"
                class="search-results-link"
                :to="`/search?q=${encodeURIComponent(query)}&type=groups`"
                @click="triggerClose"
              >
                <i18n-t scope="global" keypath="Show more" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </NavigationTopBarItemBase>
</template>

<script>
import UserItem from '../user-suggestion-item'
import Autocomplete from '~/components/auto-complete'

export default {
  name: 'Search',

  components: {
    Autocomplete,
    UserItem
  },
  data() {
    return {
      item: null,
      users: [],
      groups: [],
      loading: false,
      noResults: false,
      query: ''
    }
  },

  updated() {
    // For some reason adding auto focus as a prop to the input only works the first time the search bar is opened
    // So this is a workaround
    const input = this.$el.querySelector('input')
    if (input) {
      input.focus()
    }
  },
  methods: {
    onChange(inputString) {
      if (!inputString) {
        return
      }
      if (inputString.length === 0) {
        this.groups = []
        this.users = []
        this.noResults = false
      }
    },

    close() {
      this.groups = []
      this.users = []
      this.noResults = false
      this.query = ''
    },

    async search(query) {
      this.groups = []
      this.users = []
      this.loading = true
      this.noResults = false
      this.query = query

      const [users, groups] = await Promise.all([
        this.$SearchService.searchUsers(query),
        this.$SearchService.searchGroups(query)
      ])
      this.loading = false
      if (users.length > 0) {
        this.$UserService.setUsers(users)
      }
      this.users = users
      this.groups = groups
      this.noResults = this.users.length === 0 && this.groups.length === 0
    }
  }
}
</script>

<style scoped>
.content-container {
  @apply tw-flex tw-w-full tw-flex-col md:tw-w-96;

  min-height: 50px;

  /* 
    the list should, at max, take up 
    
    + the entire height of the page
    - the top bar's height
    - a 10px gap for aesthetic reasons  
  
    it's also limited by 450px so it doesn't get too crazily high on desktop screens
  */
  max-height: min(calc(100vh - var(--top-bar-height)) - 10px, 450px);
}

.search-results-link {
  @apply tw-mt-4 tw-inline-block tw-text-sm tw-font-bold tw-text-black/20;
}

:deep(.v-autocomplete-input) {
  @apply tw-w-full tw-rounded-lg tw-border tw-border-black/5 tw-bg-white tw-px-4 tw-py-2 tw-text-sm;
}

.v-autocomplete-list {
  @apply tw-hidden;
}
</style>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.search-results
  width: 100%
  background: rgb(var(--secondary-background-color))
  min-height: calc(100vh - #{$top-bar-height} - 51px)
  max-height: calc(100vh - #{$top-bar-height} - 51px)

.search-results-title
  font-weight: bold
  margin: $padding
  display: block

.search-results-item
  &.hover:hover
    background: rgb(var(--main-background-color))

.no-results
  margin: 2*$defaultMargin $defaultMargin
</style>
