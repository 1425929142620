import { Notification } from '~/types/notifications'
import { useAuthStore } from './auth'

interface State {
  notifications: Notification[]
  settings: Record<string, string | boolean | null> | null
  isLoadedFromApp: boolean
}

export const useMainStore = defineStore('main', {
  state: (): State => ({
    notifications: [],
    settings: {
      distance: 'metric',
      temperature: 'celsius',
      twelveHourClock: false
    },
    isLoadedFromApp: false
  }),

  actions: {
    acceptTos() {
      const authStore = useAuthStore()

      if (authStore.session_data) {
        authStore.session_data.user.accepted_latest_tos = true
      }
    },

    setNotifications(data: Notification[]) {
      this.notifications = data
    },

    updateSettings(settings: any) {
      const authStore = useAuthStore()

      if (!authStore?.session_data.user) {
        return
      }

      this.settings = { ...this.settings, ...JSON.parse(JSON.stringify(settings)) }
    },

    setIsLoadedFromApp(value: boolean) {
      this.isLoadedFromApp = value
    },

    hideScrollbar() {
      if (!document) return
      document.querySelector('body')?.classList.add('no-scrollbar')

      /* 
        On Android, scrolling upwards is sometimes not possible as soon as we hide the body overflow
        Therefore we scroll down by 1px so that the native client thinks there's something to scroll up to
      */
      window.scrollBy(0, 1)
      document.body.style.minHeight = 'calc(100vh + 1px)'
    },

    unhideScrollbar() {
      if (!document) return
      document.querySelector('body')?.classList.remove('no-scrollbar')

      window.scrollBy(0, -1)
      document.body.style.minHeight = '0px'
    }
  }
})
